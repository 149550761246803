import React, { useState, useEffect } from 'react';
import './App.css';
import { FiCheckCircle, FiXCircle, FiAlertTriangle   } from "react-icons/fi";
import { useParams, useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";
import axios from 'axios';

function App() {

  const { certificateNo } = useParams(); // Getting the certification parameter from the URL

  const [checkResponse, setCheckResponse] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [inputCertificateNo, setInputCertificateNo] = useState(certificateNo || '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // You can perform any additional operations based on the certification parameter here
    // For example, you might want to automatically trigger the check when the certification parameter changes
    if (certificateNo) {
      // Perform the check here
      console.log(`Certificate No from the URL: ${certificateNo}`);
      fetchCertificateDetails(certificateNo);
    }
  }, [certificateNo]);

  const handleInputChange = (e) => {
    setInputCertificateNo(e.target.value);
  };

  const fetchCertificateDetails = async (certificateNo) => {
    try {
      setLoading(true);
      setServerError(false);
      setCheckResponse(null);
      setCertificateDetails(null);
      const requestData = {
        certificateNr: certificateNo
      };
      const response = await axios.post('https://13.53.92.46/api/check', requestData, {
          headers: {
            'Content-Type': 'application/json',
          }
      });
      console.log('APi call made');
      const responseStatus = response.data.Status;
      const responseData = response.data.Data;
      console.log(responseData);
      if(responseStatus == 'Ok'){
        setServerError(false);
        if(responseData == 'Invalid'){
          //  no certificate with this reg-no
          setCertificateDetails(null);
          setCheckResponse(false);
          setLoading(false);
          return;
        }else{
          //  found valid certificate
          setCheckResponse(true);
          setCertificateDetails(responseData);
          setLoading(false);
          return;
        }
      }else{
        //  error while communicating with server
        setCheckResponse(null);
        setCertificateDetails(null);
        setServerError(true);
        setLoading(false);
        return;
      }
    } catch (error) {
      setServerError(true);
      console.error('Error fetching certificate details:', error);
    }
  };

  useEffect(() => {
    console.log('New cert details:');
    console.log(certificateDetails);
  }, [certificateDetails])

  const navigate = useNavigate();
  const checkClicked = () => {
    navigate(`/${inputCertificateNo}`);
  }

  return (
    <div className="App">
      <div className='content'>
        <div className='cotent_box'>
          <img src='Logo_black.png' alt='Logo' style={{width: '128px', height: 'auto'}}/>
          <p className='title'>Check a certificate</p>
          <div className='input_box'>
            <div className='input_box_top'><p>Enter Reg-No:</p></div>
            <div className='input_box_bottom'><input className='inputField' placeholder='AV0123456' value={inputCertificateNo} onChange={handleInputChange}></input></div>
          </div>

          {loading == false ? (
            <div className='check_btn' onClick={() => checkClicked()}>
              <p>Check</p>
            </div>
          ) : (
            <Loader type="spinner-default" bgColor={"orange"} size={50} />
          )}

          {serverError == true ? (
            <div className='response_negative_msg'>
              <FiAlertTriangle  style={{width: '16px'}} />
              <p>Something went wrong when communicating with server..</p>
            </div>
          ) : null}

          {checkResponse != null ? 
            checkResponse == true ? (
              <div className='response_positive_msg'>
                <FiCheckCircle style={{width: '16px'}} />
                <p>Certificate is valid</p>
              </div>
            ) : (
              <div className='response_negative_msg'>
                <FiXCircle style={{width: '16px'}}/>
                <p>No certificate with this Reg-No. has been found.</p>
              </div>
            ) : null
          }

          {certificateDetails != null ? (
            <div className='certificate_details'>
              <div className='details_item'>
                <div className='item_label'>
                  <p>Certificate</p>
                </div>
                <div className='item_value'>
                  <p>{certificateDetails.title}</p>
                </div>
              </div>
              <div className='details_item'>
                <div className='item_label'>
                  <p>First name</p>
                </div>
                <div className='item_value'>
                  <p>{certificateDetails.first_name}</p>
                </div>
              </div>
              <div className='details_item'>
                <div className='item_label'>
                  <p>Last name</p>
                </div>
                <div className='item_value'>
                  <p>{certificateDetails.last_name}</p>
                </div>
              </div>
              <div className='details_item'>
                <div className='item_label'>
                  <p>Place of birth</p>
                </div>
                <div className='item_value'>
                  <p>{certificateDetails.place_of_birth}</p>
                </div>
              </div>
              <div className='details_item'>
                <div className='item_label'>
                  <p>Date of birth</p>
                </div>
                <div className='item_value'>
                  <p>{certificateDetails.date_of_birth}</p>
                </div>
              </div>
              <div className='details_item'>
                <div className='item_label'>
                  <p>Certificate No.</p>
                </div>
                <div className='item_value'>
                  <p>{certificateDetails.certificate_nr}</p>
                </div>
              </div>
              <div className='details_item'>
                <div className='item_label'>
                  <p>Obtained on</p>
                </div>
                <div className='item_value'>
                  <p>{certificateDetails.obtained_on}</p>
                </div>
              </div>
              <div className='details_item'>
                <div className='item_label'>
                  <p>Valid through</p>
                </div>
                <div className='item_value'>
                  <p>{certificateDetails.expiry_date}</p>
                </div>
              </div>
              <div className='details_item'>
                <div className='item_label'>
                  <p>Issued by</p>
                </div>
                <div className='item_value'>
                  <p>{certificateDetails.issuer}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default App;
